import { BoxGeometry, PlaneGeometry, Mesh, MeshBasicMaterial } from 'three';
import { GRID, ROLE_Z, ROOM_COLOR } from '@/utils/Config';

export default class Ground extends Mesh {
  constructor() {
    super();
    // var loader = new TextureLoader();
    // loader.load("images/content/floor.jpg", (texture) => {

    this.geometry = new PlaneGeometry(20000, 20000, 1);
    this.material = new MeshBasicMaterial({
      color: 0xcccccc,
    });
    this._type = 'wall';
    this.position.x = 0;
    this.position.y = 0;
    this.position.z = -GRID;

    this.receiveShadow = true;
  }
}
