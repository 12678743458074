import * as THREE from 'three';

import Camera from '@/modules/room/Camera';
import { GRID, ROLE_Z } from '@/utils/Config';
export default ({ canvasW, canvasH, scene }) => {
  let camera = new Camera({
    aspect: canvasW / canvasH,
  });
  scene.add(camera);

  return camera;
};
