import * as THREE from 'three';
import Shadow from '@/modules/room/Shadow';
import Wall from '@/modules/room/Wall';
import { GRID, ROLE_Z } from '@/utils/Config';
export default ({ resources, roomData, scene }) => {
  const data = [];
  roomData.walls.forEach((el) => {
    const _resource = resources.find((x) => x.id === el.meta.id);

    const wall = new Wall({
      x: el.x,
      y: el.y,
      width: el.width,
      height: el.height,
      sceneDepth: roomData.scene.depth + 20,
      meta: el.meta,
      texture: _resource ? _resource.data : null,
      color: _resource ? null : el.meta.color,
    });
    data.push(wall);
    scene.add(wall);

    const shadow = new Shadow({
      obj: wall,
      zoom: 3,
      zoomWidth: 3,
      offsetY: 11,
      opacity: 0.1,
    });
    scene.add(shadow);
  });

  return data;
};
