import * as THREE from 'three';
// import Hls from 'hls.js';
import PlayButtonAlpha from './play_button_alpha.jpg';

const STATES = {
  UNINITIALIZED: 0,
  NOSOURCE: 1,
  LOADING: 2,
  READY: 3,
};

class THREEVideoPlayer extends THREE.Mesh {
  constructor(width = 360, height = 240, options = {}) {
    super();

    this.geometry = new THREE.PlaneGeometry(width, height);
    this.material = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      // shininess: 50,
    });

    this.rotation.x = THREE.Math.degToRad(90);
    this.rotation.y = THREE.Math.degToRad(180);
    if (options.volume) {
      this.setVolume(options.volume);
    }

    this.state = 0;
    // this.hls = new Hls();
    this.init(options);
  }
  init(options) {
    this._createVideoDom(options.width, options.height);
    this._createPlayButton();

    this._setState(STATES.NOSOURCE).then(() => {
      if (options.source) {
        try {
          this._setSource(options.source);
        } catch (e) {
          console.log(e);
        }
      }
    });
  }

  async _setState(nState) {
    if (Object.values(STATES).indexOf(nState) === -1 || nState === this.state) {
      return;
    }

    this.state = nState;

    switch (this.state) {
      case this.state.NOSOURCE:
        this.material.map = null;
        this.material.needsUpdate = true;
        // this.visible = true;
        this._playButtonObject.visible = false;
        break;
      case STATES.LOADING:
        break;
      case STATES.READY:
        this.material.map = new THREE.VideoTexture(this._videoDOMElement);
        this.material.map.needsUpdate = true;
        this.material.needsUpdate = true;
        // this.visible = true;
        this._playButtonObject.visible = true;
        break;
      default:
        return;
    }

    return;
  }

  _createVideoDom() {
    this._videoDOMElement = document.createElement('video');
    this._videoDOMElement.setAttribute('style', 'display:none;');
    this._videoDOMElement.setAttribute('crossOrigin', 'anonymous');

    this._videoDOMElement.setAttribute('preload', 'auto');
    this._videoDOMElement.setAttribute('playsinline', 'playsinline');
    this._videoDOMElement.setAttribute(
      'webkit-playsinline',
      'webkit-playsinline'
    );
    // this._videoDOMElement.muted = true;
    this._videoDOMElement.autoplay = false;
    this._videoDOMElement.loop = false;
    document.body.appendChild(this._videoDOMElement);
  }
  _createPlayButton() {
    this._playButtonObject = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(30, 30),
      new THREE.MeshBasicMaterial({
        color: 0xc1c1c0,
        alphaMap: new THREE.TextureLoader().load(PlayButtonAlpha),
        alphaTest: 0.3,
      })
    );

    // Add _playButtonObject as child of object

    this._playButtonObject.position.z = 0.6;
    this._playButtonObject.visible = true;

    this._playButtonObject.disableRaycast = true;

    // Show play button on video pause & end
    this._videoDOMElement.onpause = () => {
      this._playButtonObject.visible = true;
    };
    this._videoDOMElement.onended = () => {
      this._playButtonObject.visible = true;
    };

    // Hide play button on video play
    this._videoDOMElement.onplay = () => {
      this._playButtonObject.visible = false;
    };
    this.add(this._playButtonObject);
  }

  setSource(source) {
    var status = true;
    try {
      this._setSource(source);
    } catch (e) {
      console.log(e);
      status = false;
    } finally {
      // return;
    }
  }

  // Define private setSource method
  _setSource(source) {
    // Return if uninitialized
    if (this.state === STATES.UNINITIALIZED) {
      return;
    }

    // Check for valid method call
    if (typeof source !== 'string') {
      throw 'New source must be a string!';
    }

    // Remove any existing sources
    while (this._videoDOMElement.firstChild) {
      this._videoDOMElement.removeChild(this._videoDOMElement.firstChild);
    }

    // Create new source DOM element
    var nSourceDOMElement = document.createElement('source');
    nSourceDOMElement.src = source;
    nSourceDOMElement.type = 'video/mp4';
    this._videoDOMElement.appendChild(nSourceDOMElement);

    // if (this._videoDOMElement.canPlayType('application/vnd.apple.mpegurl')) {
    //   this._videoDOMElement.src = source;
    // } else if (Hls.isSupported()) {
    //   console.log('Hls supported');
    //   this.hls.loadSource(source);
    //   this.hls.attachMedia(this._videoDOMElement);
    // }

    // Set state to STATES.READY when oncanplay is called
    this._videoDOMElement.oncanplay = () => {
      this._setState(STATES.READY).then(() => {
        console.log('ready');
        return;
      });
    };

    // Set state to STATES.LOADING and load new source
    this._setState(STATES.LOADING).then(() => {
      this._videoDOMElement.load();
    });
  }

  // Define _clearSource method
  clearSource() {
    this._videoDOMElement.pause();
    if (this.state !== STATES.UNINITIALIZED && this.state !== STATES.NOSOURCE) {
      // this.hls.destroy();
      this._setState(STATES.NOSOURCE).then(() => {
        while (this._videoDOMElement.firstChild) {
          this._videoDOMElement.removeChild(this._videoDOMElement.firstChild);
        }
        console.log(this._videoDOMElement.firstChild);
      });
    }
  }
  play() {
    console.log('play');
    if (this.state === STATES.READY) {
      this._videoDOMElement.play();
    }
  }
  pause() {
    if (this.state === STATES.READY) {
      this._videoDOMElement.pause();
    }
  }
  canPlay() {
    return this.state === STATES.READY;
  }
  isPaused() {
    if (this._videoDOMElement !== undefined) {
      return this._videoDOMElement.paused;
    }
  }

  // clearSource: _clearSource,
  setMuted(isMuted) {
    if (this._videoDOMElement !== undefined) {
      this._videoDOMElement.muted = new Boolean(isMuted);
    }
  }
  isMuted() {
    if (this._videoDOMElement !== undefined) {
      return this._videoDOMElement.muted;
    }
  }
  setAutoplay(isAutoplay) {
    if (this._videoDOMElement !== undefined) {
      this._videoDOMElement.autoplay = new Boolean(isAutoplay);
    }
  }
  isAutoplay() {
    if (this._videoDOMElement !== undefined) {
      return this._videoDOMElement.autoplay;
    }
  }
  setLoop(isLoop) {
    if (this._videoDOMElement !== undefined) {
      this._videoDOMElement.loop = new Boolean(isLoop);
    }
  }
  isLoop() {
    if (this._videoDOMElement !== undefined) {
      return this._videoDOMElement.loop;
    }
  }

  getVolume() {
    if (this._videoDOMElement !== undefined) {
      return this._videoDOMElement.volume;
    }
  }
  setVolume(volume) {
    if (this._videoDOMElement !== undefined) {
      this._videoDOMElement.volume =
        volume > 1.0 ? 1.0 : volume < 0.0 ? 0.0 : volume;
    }
  }
}
// Export object
export { THREEVideoPlayer };
