import * as THREE from 'three';
import Shadow from '@/modules/room/Shadow';
import Poster from '@/modules/room/Poster';
import { GRID, ROLE_Z, ROOM_HEIGHT } from '@/utils/Config';
export default ({ roomData, resources, scene }) => {
  if (!roomData.poster) return;

  const texture = resources.find((x) => x.id === 'poster');

  if (!texture) return;

  let poster = new Poster({
    scene,
    texture: texture.data,
    x: roomData.scene.width / 2 - GRID * 30,
    y: roomData.scene.height / -2 - GRID - 5,
    l: ROOM_HEIGHT,
    w: 5,
    h: ROOM_HEIGHT * (texture.data.image.height / texture.data.image.width),
  });

  scene.add(poster);

  return poster;
};
