import {
  DoubleSide,
  Mesh,
  BoxGeometry,
  SpotLight,
  SpotLightHelper,
  MeshPhongMaterial,
  Group,
  MeshBasicMaterial,
  Vector2,
  SpriteMaterial,
  Sprite,
  TextureLoader,
  Math as _Math,
} from 'three';

import { GRID, ROLE_Z } from '@/utils/Config';
export default class Spot extends Group {
  constructor(params) {
    const {
      id,
      x,
      y,
      z,
      lookAt,
      scene,
      type,
      title,
      texture,
      size,
      offset,
      visible,
    } = params;

    super();
    this._id = id;
    this.title = title;
    this.scene = scene;
    this.position.x = x;
    this.position.y = y;
    this.position.z = 0;
    this.z = z ? z : 40;
    this.lookAt = lookAt;
    this.size = size;
    this._active = false;
    this.type = type;
    this.texture = texture;
    this.offset = offset ? offset : { x: 0, y: 0, z: 0 };
    this.visible = visible;
    this.render();

    this._init();
  }
  _init() {}
  toggle() {
    this._active = !this._active;
  }

  render() {
    const material = new SpriteMaterial({ map: this.texture });
    const sprite = new Sprite(material);
    sprite.position.set(this.offset.x, this.offset.y, this.z + this.offset.z);
    sprite.width = 80;
    const size = this.size ? this.size : 20;
    sprite.scale.set(size, size, size);
    sprite._id = this._id;

    sprite.visible = this.visible;

    this.add(sprite);
  }
  show() {
    this.visible = !this.visible;
  }

  getLookAt() {
    return this.lookAt;
  }
}
