import * as THREE from 'three';

import { reactive } from 'vue';

export default () => {
  let activeItem = reactive({
    id: 'poster',
    item: null,
  });
  let items = [];
  const setItems = (_items) => {
    items = _items;
  };
  const setActiveItem = (id) => {
    if (!items || !id) return;
    activeItem.id = id;

    if (activeItem.item) {
      activeItem.item.leave();
    }

    activeItem.item = items.find((x) => x._id === id);
    if (activeItem.item) {
      activeItem.item.hitten();
    }
  };
  return { activeItem, setActiveItem, setItems };
};
