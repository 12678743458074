<template>
  <div
    class="flex flex-wrap lg:h-full min-h-[200px]"
    :class="row.type === 5 ? 'h-full' : ''"
    v-loading="loading"
  >
    <div class="w-full lg:w-0 lg:flex-grow lg:h-full lg:overflow-hidden">
      <component
        v-if="!loading"
        class="bg-gray-100"
        :images="row.gallery"
        :videouri="row.videouri"
        :mp4="row.mp4"
        :pic="row.pic"
        :title="row.title"
        :excerpt="row.excerpt"
        :model_url="row.file1"
        :is="cmpt"
      ></component>
    </div>

    <div
      v-if="!loading && (row.work_id || (row.type === 5 && row.excerpt))"
      class="w-full lg:p-10 p-6 lg:h-full lg:w-3/12"
    >
      <c-scrollbar class="pr-4 mr-2" width="100%" height="100%">
        <h2
          class="font-bold text-3xl tracking-widest flex-none"
          v-if="row.type !== 5"
        >
          展品說明
        </h2>
        <div class="pt-8">
          <Info :row="row"></Info>
          <div v-if="row.excerpt">
            <div class="mt-8 whitespace-pre-line">{{ row.excerpt }}</div>
          </div>
          <Materials v-if="row.materials" :rows="row.materials"></Materials>
        </div>
      </c-scrollbar>
    </div>
  </div>
</template>

<script>
import { reactive, inject, computed, ref, onMounted } from 'vue';

import Info from '@/partials/detail/_Info.vue';
import { ITEM_TYPES } from '@/utils/Config';
import plainDetail from '@/partials/detail/_Plain.vue';
import videoDetail from '@/partials/detail/_Video.vue';
import pictureDetail from '@/partials/detail/_Picture.vue';
import introDetail from '@/partials/detail/_Intro.vue';
import solidDetail from '@/partials/detail/_Solid.vue';
import Materials from '@/components/Materials';
import SelectionService from '@/services/Selection';
import WorkService from '@/services/Work';
import { ElMessageBox } from 'element-plus';
import { pass } from '@/utils/Track';
import { CScrollbar } from 'c-scrollbar';
export default {
  components: {
    Info,
    videoDetail,
    pictureDetail,
    introDetail,
    plainDetail,
    solidDetail,
    Materials,
    CScrollbar,
  },
  setup() {
    const activeItem = inject('activeItem');
    const row = reactive({});
    const cmpt = computed(() => {
      let type = ITEM_TYPES[row.type];

      if (row.type === 5 && (row.videouri || row.mp4)) {
        type = 'video';
      } else {
        type = ITEM_TYPES[row.type];
      }
      return type + 'Detail';
    });

    const loading = ref(false);
    onMounted(async () => {
      loading.value = true;
      const res = await SelectionService.find({ id: activeItem.id });
      if (res.code !== 1) {
        await ElMessageBox.alert(
          '展品不存在於展品清單中，請回到編輯畫面確認展品資料。',
          { center: true }
        );
        return;
      }
      row.type = res.data.type;

      row.work_id = res.data.work_id > 0 ? res.data.work_id : null;

      if (row.type === 5) {
        row.videouri = res.data.videouri;
        row.mp4 = res.data.mp4;
        row.pic = res.data.pic;
      }

      if (row.work_id) {
        const _res = await WorkService.find({ id: row.work_id });

        Object.assign(row, {
          ..._res.data,
          gallery: _res.data.gallery
            ? [..._res.data.gallery]
            : [_res.data.cover],
        });
        const materialsRes = await WorkService.materials({ id: row.work_id });

        if (materialsRes.code === 1) {
          row.materials = materialsRes.data.subset;
        }
      }
      row.title = res.data.title;
      row.excerpt = res.data.excerpt;
      loading.value = false;

      if (activeItem.id) {
        pass({
          ea: 'open',
          ec: 'chip',
          el: activeItem.id,
        });

        window.gtag('event', 'open', {
          event_category: 'chip',
          event_label: activeItem.id,
        });
      }

      if (row.work_id) {
        pass({
          ea: 'open',
          ec: 'work_room',
          el: row.work_id,
        });
        window.gtag('event', 'open', {
          event_category: 'work_room',
          event_label: row.work_id,
        });
      }
    });

    return { row, cmpt, loading };
  },
};
</script>
