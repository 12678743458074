<template>
  <div
    class="flex justify-center items-center w-full lg:h-full bg-black"
    :class="type === 'mp4' ? ' h-full' : ''"
  >
    <div class="w-full" style="aspect-ratio: 16/9" v-if="uri && type">
      <video v-if="type === 'mp4'" class="w-full h-full" controls>
        <source :src="uri" type="video/mp4" />
        Your browser does not support HTML video.
      </video>
      <!-- src="https://www.youtube.com/embed/JPrQtCRRd8U"/ -->

      <iframe
        v-if="type === 'yt'"
        class="w-full h-full"
        :src="uri"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        mozallowfullscreen
      ></iframe>

      <iframe
        v-if="type === 'imedia'"
        class="w-full h-full"
        :src="uri"
        frameborder="0"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
      ></iframe>
    </div>
  </div>

  <!-- //imedia.culture.tw/s3/uq/2b44ad82cb.mp4?api_access_key= -->
  <!-- //imedia.culture.tw/iframe/2b44ad82cb?api_access_key= -->
</template>

<script>
import { toRefs, ref } from 'vue';

export default {
  props: {
    videouri: {
      type: String,
    },
    mp4: {
      type: String,
    },
  },

  setup(props) {
    const { mp4, videouri } = toRefs(props);
    const uri = ref('');

    const type = ref(null);

    if (mp4.value) {
      type.value = 'mp4';
      uri.value = window.$filters.uploadUrl(mp4.value);
    } else if (videouri.value.startsWith('//imedia.culture.tw/iframe/')) {
      type.value = 'imedia';
      uri.value = `${videouri.value}${process.env.VUE_APP_MEDIA_ACCESS_KEY}`;
    } else if (videouri.value.startsWith('https://youtu.be/')) {
      type.value = 'yt';
      const url = videouri.value.split('/');
      uri.value = `https://www.youtube.com/embed/${url[url.length - 1]}`;
    } else {
      type.value = null;
    }

    return { uri, type };
  },
};
</script>
