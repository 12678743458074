<template>
  <div class="lg:p-14 p-10">
    <h2 class="text-3xl font-bold">{{ title }}</h2>
    <div class="wysiwyg mt-8 whitespace-pre-line">{{ excerpt }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    excerpt: {
      type: String,
    },
  },
  setup(props) {
    return {};
  },
};
</script>
