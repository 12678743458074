import {
  DoubleSide,
  Mesh,
  BoxGeometry,
  SpotLight,
  SpotLightHelper,
  MeshPhongMaterial,
  Group,
  MeshBasicMaterial,
  Vector2,
  Vector3,
  Math as _Math,
} from 'three';

import { GRID, ROLE_Z, ROOM_HEIGHT } from '@/utils/Config';

export default class Item extends Group {
  constructor(params) {
    const {
      id,
      resource,
      x,
      y,
      width,
      height,
      sceneDepth,
      rotation,
      meta,
      scene,
    } = params;
    super();
    this._id = id;
    this.meta = meta;
    this.scene = scene;
    this.position.x = x;
    this.position.y = y;
    this.lookAtPos = {};
    this.width = width;
    this.height = height;

    this.sceneDepth = sceneDepth;

    // this.depth = depth;
    this.resource = resource;

    this.position.z = 0;
    if (rotation) {
      let _rotation = rotation === -90 ? 270 : rotation;
      this.rotation.z = _Math.degToRad(_rotation);
    }
    this._obj = this.render();
    this.setSpotLight();
    this.setlookAtPos();
  }
  setlookAtPos() {
    this.lookAtPos = this._obj.getWorldPosition(new Vector3());
  }

  render() {
    return null;
  }
  setSpotLight() {
    const spotLight = new SpotLight(0xffe0ce);
    // spotLight.angle = Math.PI / (this.meta.l > 200 ? 4 : 10);

    spotLight.angle = Math.PI / ((this.sceneDepth / 900) * 10);
    spotLight.penumbra = 0.8;

    spotLight.distance = this.sceneDepth + 60;
    // spotLight.decay = 1;
    spotLight.position.set(0, 45, this.sceneDepth + 30);
    spotLight.target.position.set(0, -45, 0);

    this.spotLight = spotLight;
    this.add(spotLight);
    this.add(spotLight.target);
    spotLight.updateMatrixWorld();

    // const spotLightHelper = new SpotLightHelper(spotLight);
    // this.scene.add(spotLightHelper);
    this.setDefaultLight();
  }
  setDefaultLight() {
    this.spotLight.visible = false;
    this.spotLight.intensity = 1;
  }
  hitten() {
    this.spotLight.visible = true;
    this.toggleLight(6);
  }
  leave() {
    this.setDefaultLight();
  }
  toggleLight(intensity) {
    this.spotLight.intensity = intensity;

    // this.spotLight.shadow.map.texture.dispose();
  }
}
