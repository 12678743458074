import { ref, nextTick } from 'vue';
import { ElMessageBox } from 'element-plus';
import { ROLE_Z } from '@/utils/Config';

import { useRoute } from 'vue-router';
export default ({ spots, roomData, ctrl, isPreview }) => {
  const inited = ref(false);


  const enter = () => {
    if (inited.value) return;
    inited.value = true;

    if (isPreview) return
    // console.log(route);

    ctrl
      .lookAround({
        targetSpot: spots[roomData.poster ? 2 : 1],
        scene: roomData.scene,
      })
      .then(() => {
        // ElMessageBox.alert('開始瀏覽說明', { center: true });
      });
  };

  const start = () => {
    nextTick(() => {
      ctrl.set({
        x: 0,
        y: roomData.scene.height * -1 - 800,
        z: ROLE_Z,
      });
    });
    const test = process.env.NODE_ENV === 'production' ? false : false;

    // roomData.scene.start = 'inside';
    let start = 'door';

    if (test) {
      setTimeout(() => {
        ctrl.goto(1);
      }, 100);
    } else {
      setTimeout(async () => {
        if (roomData.scene.start !== '2') {

          await ctrl.walkTo(spots[0]);
        } else {
          ctrl.goto(roomData.poster ? 1 : 0);
        }

        if (spots[ctrl.activeIdx.value]._id === 'entry') {
          enter();
        }
      }, 100);
    }
  };
  return { start, enter };
};
