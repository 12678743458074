import * as THREE from 'three';
import Shadow from '@/modules/room/Shadow';
import Roof from '@/modules/room/Roof';
import Floor from '@/modules/room/Floor';
import Ground from '@/modules/room/Ground';
import { GRID, ROLE_Z, TEXTURES, FLOOR_TEXTURE } from '@/utils/Config';
export default ({ resources, roomData, scene }) => {
  var loader = new THREE.TextureLoader();

  let rid = null;

  if (!roomData.scene.floor) {
    rid = FLOOR_TEXTURE;
  } else if (roomData.scene.floor.texture) {
    rid = roomData.scene.floor.texture;
  }

  let floor = new Floor({
    texture: resources.find((x) => x.id === rid)?.data,
    color: roomData.scene.floor?.color || '#ffffff',
    width: roomData.scene.width + GRID * 2,
    height: roomData.scene.height + GRID * 2,
  });
  scene.add(floor);

  loader.load(require('@/assets/floorblur.png'), (texture) => {
    let shadow = new THREE.Mesh();

    shadow.geometry = new THREE.PlaneGeometry(
      roomData.scene.width,
      roomData.scene.height,
      1
    );

    shadow.material = new THREE.MeshPhongMaterial({
      color: '#000000',
      map: texture,
      opacity: 0.25,
      transparent: true,
    });

    shadow.position.set(0, 0, 3);
    scene.add(shadow);
  });

  const resource = resources.find((x) => x.id === 'outer');

  const appearance = {
    texture: resource ? resource.data : null,
    color: roomData.scene.outer?.color || roomData.scene.color,
  };

  const roof = new Roof({
    ...appearance,
    width: roomData.scene.width + GRID * 2,
    height: roomData.scene.height + GRID * 2,
    sceneDepth: roomData.scene.depth,
    x: 0,
    y: 0,
  });
  scene.add(roof);
  const ground = new Ground();
  scene.add(ground);
  return {
    roof,
    floor,
    ground,
  };
};
