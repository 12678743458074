import * as THREE from 'three';
import Light from '@/modules/room/Light';
import { GRID, ROLE_Z } from '@/utils/Config';
export default ({ roomData, scene }) => {
  const lights = new Light({
    roomData,
    width: roomData.scene.width,
    height: roomData.scene.height,
    scene,
  });
  return { lights };
};
