<template>
  <div class="flex flex-wrap relative">
    <div class="w-full">
      <div ref="canvasRef">
        <div id="mapCanvas"></div>
      </div>
    </div>

    <div class="absolute w-full right-0 bottom-0 transform translate-y-[100%]">
      <div class="w-full flex">
        <el-button
          icon="el-icon-arrow-up"
          class="dark !py-1 !text-xs !border-0 !px-2 w-full"
          size="mini"
          @click="prev"
          :disabled="activeIdx === 1"
        >
          上一件作品
        </el-button>
        <span
          class="
            bg-black
            text-center text-white
            !py-1
            !text-xs
            !border-0
            !px-2
            w-full
            truncate
          "
        >
          精選導覽
        </span>
        <el-button
          class="dark !py-1 !text-xs !border-0 !px-2 w-full"
          size="mini"
          @click="next"
          :disabled="rows.length === activeIdx + 1"
          >下一件作品 <i class="transform el-icon-arrow-down"></i
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, ref } from 'vue';
import useMap from './useMap';
import { useResizeObserver } from '@vueuse/core';
import { ElButton } from 'element-plus';
export default {
  components: { ElButton },
  setup() {
    const { spotList, goto, next, prev, activeIdx } = inject('ctrl');
    const activeItem = inject('activeItem');

    const printData = inject('printData');
    const canvasRef = ref(null);
    const { resizeCanvas, initMap } = useMap(printData, activeItem);

    useResizeObserver(canvasRef, (entries) => {
      const entry = entries[0];
      if (!entry) return;

      const { width, height } = entry.contentRect;
      resizeCanvas(width, height);
    });

    onMounted(() => {
      initMap();
    });

    return { activeIdx, rows: spotList, goto, canvasRef, next, prev };
  },
};
</script>

<style></style>
