import {
  PlaneGeometry,
  DoubleSide,
  Mesh,
  MeshPhongMaterial,
  TextureLoader,
  Box3,
} from 'three';
import { GRID, ROLE_Z } from '@/utils/Config';

import shadowImg from '@/assets/shadow-drop.png';
export default class Shadow extends Mesh {
  constructor(params) {
    const { obj, offsetX, offsetY, zoom, opacity, zoomWidth } = params;
    super();
    this._obj = obj;
    this.offsetY = offsetY || 0;
    this.offsetX = offsetX || 0;
    this.zoom = zoom || 1;
    this.zoomWidth = zoomWidth || 1;
    this.opacity = opacity || 0.4;
    // this.renderDepth = 1;
    this.render();
  }
  render() {
    let _box = new Box3().setFromObject(this._obj);
    const posZ =
      this._obj.position.z - (_box.max.z - _box.min.z) / 2 + 0.2 + this.offsetY;
    const width = _box.max.x - _box.min.x + GRID * this.zoom * this.zoomWidth;
    const height = _box.max.y - _box.min.y + GRID * this.zoom;
    this.geometry = new PlaneGeometry(width, height);

    this.material = new MeshPhongMaterial({
      // side: DoubleSide,
      color: '#000',
      map: new TextureLoader().load(shadowImg),
      opacity: this.opacity,
      transparent: true,
    });
    this.material.depthWrite = false;
    this.position.set(this._obj.position.x, this._obj.position.y, posZ);
  }
}
