import * as THREE from 'three';
import { ref } from 'vue';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import { GRID, ROLE_Z, TEXTURES, FLOOR_TEXTURE, FRAMES } from '@/utils/Config';

import { uploadUrl } from '@/utils/Utils';
import { mergeMesh } from '@/modules/room/utils/MeshTransform';
export const percentage = ref(0);
export const usePreload = ({ roomData }) => {
  const _resources = [];
  const _textures = TEXTURES;
  const _frames = FRAMES;
  const manager = new THREE.LoadingManager();
  const _GLTFLoader = new GLTFLoader(manager);
  const _TextureLoader = new THREE.TextureLoader(manager);
  const _SVGLoader = new SVGLoader(manager);

  _GLTFLoader.setWithCredentials(true);
  _GLTFLoader.setCrossOrigin('');
  _TextureLoader.setWithCredentials(true);
  _TextureLoader.setCrossOrigin('');
  _SVGLoader.setWithCredentials(true);
  _SVGLoader.setCrossOrigin('');
  return new Promise((resolve, reject) => {
    manager.onStart = function (url, itemsLoaded, itemsTotal) { };

    manager.onLoad = function () {
      console.log('Loading complete!');
      percentage.value = 100;

      let hash = {};
      const data2 = _resources.reduce((preVal, curVal) => {
        hash[curVal.id] ? '' : (hash[curVal.id] = true && preVal.push(curVal));
        return preVal;
      }, []);

      return resolve(data2);
    };

    manager.onProgress = function (url, itemsLoaded, itemsTotal) {
      percentage.value = (itemsLoaded / itemsTotal) * 100;

      // console.log(
      //   'Loading file: ' +
      //     '.\nLoaded ' +
      //     itemsLoaded +
      //     ' of ' +
      //     itemsTotal +
      //     ' files.'
      // );
    };

    manager.onError = function (url) {
      console.log(url);

      console.log('There was an error loading ' + url);
    };

    const urls = [
      { id: 'iconStart', url: require('@/assets/hotspot-initial.svg') },
      { id: 'iconEntry', url: require('@/assets/entrance-arrow.svg') },
      { id: 'icon2', url: require('@/assets/hotspot-painting.svg') },
      { id: 'icon3', url: require('@/assets/hotspot-3d.svg') },
      { id: 'icon4', url: require('@/assets/hotspot-text.svg') },
      { id: 'icon5', url: require('@/assets/hotspot-picture.svg') },
      { id: 'shadow', url: require('@/assets/shadow.png') },
      { id: 'shadowDrop', url: require('@/assets/shadow-drop.png') },
    ];
    if (roomData.poster) {
      urls.push({ id: 'poster', url: uploadUrl(roomData.poster) });
    }

    let floorId = roomData.scene?.floor?.texture
      ? roomData.scene.floor.texture
      : FLOOR_TEXTURE;

    if (floorId === '94_Walnut-dark-wood-fine-texture-seamless') {
      floorId = '108_Walnut-fine-wood-texture-seamless';
    }

    urls.push({
      id: floorId,
      url: require(`@/assets/texture/${_textures[floorId].img}`),
      // url: require('@/assets/floor.jpg'),
    });

    const outerId = roomData.scene?.outer?.texture;
    if (outerId) {
      urls.push({
        id: 'outer',
        url: require(`@/assets/texture/${_textures[outerId].img}`),
        // url: require('@/assets/floor.jpg'),
      });
    }

    urls.forEach((x) => {
      _TextureLoader.load(
        x.url,
        (texture) => {
          _resources.push({ id: x.id, data: texture });
        },
        undefined,
        (err) => {
          console.log(err);
          console.error('An error happened.');
        }
      );
    });

    roomData.walls.forEach((x) => {
      if (x.meta.texture) {
        _TextureLoader.load(
          require(`@/assets/texture/${_textures[x.meta.texture].img}`),
          (texture) => {
            _resources.push({ id: x.id, data: texture });
          },
          undefined,
          (err) => {
            // console.log(err.target);
            console.error('An error happened.');
          }
        );
      }
    });

    roomData.items.forEach((x) => {
      if (x.meta.type === 2 || x.meta.type === 5 || x.meta.type === 4) {
        if (x.meta.pic) {
          _TextureLoader.load(
            uploadUrl(x.meta.pic, x.meta.type === 2 ? 'md' : null),
            (texture) => {
              _resources.push({ id: x.id, data: texture });
            },
            undefined,
            (err) => {
              console.log(err);
              // console.log(err.target);
              console.error('An error happened.');
            }
          );
        }

        if (
          x.meta?.extra?.frame &&
          !_resources.some((x) => x.id === x.meta?.extra?.frame)
        ) {
          let _data = { id: x.meta.extra.frame, data: {} };

          _TextureLoader.load(
            require(`@/assets/frame/${x.meta.extra.frame}/texture.jpg`),
            (texture) => {
              _data.data.texture = texture;
            },
            undefined,
            (err) => {
              console.error('An error happened.');
            }
          );
          _SVGLoader.load(
            require(`@/assets/frame/${x.meta.extra.frame}/shape.svg`),
            (data) => {
              const paths = data.paths;
              const path = paths[0];

              const shape = SVGLoader.createShapes(path);
              shape.autoClose = true;
              // const points = shape.getPoints(15);

              _data.data.shape = shape;
            },
            undefined,
            (err) => {
              // console.log(err.target);
              console.error('An error happened.');
            }
          );
          _resources.push(_data);
        }
      } else if (x.meta.type === 3) {
        let meshes = [];
        let mesh;
        // console.log(x);
        _GLTFLoader.load(
          uploadUrl(x.meta.model),
          (gltf) => {
            gltf.scene.traverse(function (child) {
              if (child.isMesh) {
                meshes.push(child);
              }
            });

            mesh = mergeMesh(meshes);

            mesh.geometry.center();
            mesh.rotation.z = THREE.Math.degToRad(90);
            mesh.scale.set(0.1, 0.1, 0.1);
            _resources.push({
              id: x.id,
              data: mesh,
            });
          },
          undefined,
          (err) => {
            console.error(err);
          }
        );

        if (
          x.meta?.extra?.texture &&
          !_resources.some((x) => x.id === x.meta?.extra?.texture)
        ) {
          _TextureLoader.load(
            require(`@/assets/texture/${_textures[x.meta.extra.texture].img}`),
            (texture) => {
              _resources.push({ id: x.meta.extra.texture, data: texture });
            },
            undefined,
            (err) => {
              // console.log(err.target);
              console.error('An error happened.');
            }
          );
        }
      }
    });
  });
};
