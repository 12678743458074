import * as THREE from 'three';
import Shadow from '@/modules/room/Shadow';
import Outer from '@/modules/room/Outer';
import { GRID, ROLE_Z, ROOM_COLOR } from '@/utils/Config';
export default ({ resources, roomData, scene }) => {
  const resource = resources.find((x) => x.id === 'outer');

  const appearance = {
    texture: resource ? resource.data : null,
    color: roomData.scene.outer?.color || roomData.scene.color || ROOM_COLOR,
    exterior:
      roomData.scene.outer?.exterior || roomData.scene.exterior || ROOM_COLOR,
  };

  const _width = roomData.scene.width;
  const _height = roomData.scene.height;
  const _sceneDepth = roomData.scene.depth;
  const top = new Outer({
    ...appearance,
    width: _width + GRID * 2,
    sceneDepth: _sceneDepth,
    x: 0,
    y: _height / 2 + GRID / 2,
    rotation: 0,
  });

  scene.add(top);

  // shadow = new Shadow({
  //   obj: top,
  //   ...shadowCongig,
  // });

  // scene.add(shadow);
  const right = new Outer({
    sceneDepth: _sceneDepth,
    ...appearance,
    width: _height + GRID,
    x: _width / 2 + GRID / 2,
    y: 0,
    rotation: 270,
  });
  scene.add(right);
  // shadow = new Shadow({
  //   obj: right,
  //   ...shadowCongig,
  // });
  // scene.add(shadow);

  const left = new Outer({
    sceneDepth: _sceneDepth,
    ...appearance,
    width: _height + GRID,
    x: (_width / 2) * -1 - GRID / 2,
    y: 0,
    rotation: 90,
  });

  scene.add(left);

  // shadow = new Shadow({
  //   obj: left,
  //   ...shadowCongig,
  // });

  const down = new Outer({
    sceneDepth: _sceneDepth,
    ...appearance,
    width: _width + GRID * 2,
    x: 0,
    y: (_height / 2) * -1 - GRID / 2,
    rotation: 180,
  });
  // scene.add(down);
  const doorPos = {
    x: roomData.scene.width / 2 - GRID * 8,
    y: (roomData.scene.height / 2) * -1,
  };

  const door = down.createDoor({
    ...appearance,
    width: 240,
    height: 200,
    depth: 420,
    ...doorPos,
    z: 210,
  });

  scene.add(door);

  // shadow = new Shadow({
  //   obj: down,
  //   ...shadowCongig,
  // });

  // scene.add(shadow);
  return { top, door, left, right };
};
