<template>
  <div
    class="w-full flex flex-col overflow-hidden"
    :class="isFull ? fullSty : ''"
  >
    <div class="bg-gray-400 flex-none flex">
      <div class="inline-flex ml-auto px-6 py-4">
        <Audio-Player v-if="bgm" :url="bgm" />

        <a href="#" @click.prevent="toggleFull" class="px-2">
          <El-icon color="#fff" :size="24">
            <img class="invert" src="@/assets/full-screen.svg" alt="" />
          </El-icon>
        </a>
        <a
          href="#"
          class="px-2"
          @click.prevent="
            dialog.toggle({
              component: 'MapControl',
              attrs: {
                width: !$state.isMobile
                  ? '20%'
                  : $state.isLandscape
                  ? '60%'
                  : '100%',
                'show-close': false,
                'custom-class': 'is-right is-headerless ',
                top: $state.isMobile && $state.isLandscape ? '2rem' : '7rem',
              },
            })
          "
        >
          <El-icon color="#fff" :size="24">
            <img class="invert" src="@/assets/floorplan.svg" alt="" />
          </El-icon>
        </a>

        <template v-if="$route.name === 'Room'">
          <a href="#" @click.prevent="toggleIsSharing" class="px-2">
            <El-icon color="#fff" :size="24">
              <img class="invert" src="@/assets/share.svg" alt="" />
            </El-icon>
          </a>
          <El-Dialog
            v-model="isSharing"
            :title="'社群分享'"
            custom-class="max-w-[400px]"
            width="96%"
          >
            <Socials></Socials>
          </El-Dialog>
        </template>

        <a
          href="#"
          @click.prevent="
            $modal.toggle({
              component: 'Visit',
            })
          "
          class="px-2"
        >
          <El-icon color="#fff" :size="24">
            <img class="invert" src="@/assets/howto.svg" alt="" />
          </El-icon>
        </a>
      </div>
    </div>
    <div class="flex-grow relative h-0">
      <transition name="progress">
        <div
          class="
            absolute
            top-0
            left-0
            w-full
            h-full
            flex
            justify-center
            items-center
          "
          v-if="percentage < 100"
        >
          <El-Progress
            :show-text="false"
            type="circle"
            color="var(--color-primary)"
            :percentage="percentage"
          />
        </div>
      </transition>

      <div ref="canvasRef" class="w-full h-full" id="room"></div>
    </div>
  </div>
  <El-Dialog
    v-bind="dialog.state.attrs"
    v-model="dialog.state.show"
    @close="dialog.toggle()"
    :destroy-on-close="true"
  >
    <component
      v-if="dialog.state.component"
      :is="dialog.state.component"
    ></component>
  </El-Dialog>
</template>

<script>
import {
  onMounted,
  reactive,
  provide,
  ref,
  onBeforeUnmount,
  inject,
} from 'vue';
import * as THREE from 'three';

import { GRID, ROOM_HEIGHT } from '@/utils/Config';
import useDialog from '@/components/useDialog';
import konva2Three from '@/modules/room/utils/konva2Three';
import Socials from '@/components/Socials';

import { ElDialog, ElIcon, ElProgress, ElMessageBox } from 'element-plus';
import { MapLocation } from '@element-plus/icons';
import { useRoute, useRouter } from 'vue-router';
import { useResizeObserver } from '@vueuse/core';
import ExhibService from '@/services/Exhib';

import SelectionService from '@/services/Selection';
import useCtrl from '@/pages/index/components/three/useCtrl';
import MapControl from '@/pages/index/components/three/_Map.vue';

import Detail from '@/pages/index/components/three/work/Detail.vue';
import useStatus from './use/status';
import useFull from '@/pages/index/components/useFull';
import useScene from './use/scene';
import useOuter from './use/outer';
import useWall from './use/wall';
import useFloor from './use/floor';
import useSpots from './use/spot';
import useLights from './use/light';
import useItems from './use/items';
import usePoster from './use/poster';
import useRenderer from './use/renderer';
import useCamera from './use/camera';
import useControls from './use/controls';
import useIntersect from './use/intersect';
import useActiveItem from './use/activeItem';
import useUpdate from './use/update';
import { usePreload, percentage } from './use/preload';
import useAnimation from './useAnimation';
import AudioPlayer from './_Audio.vue';

export default {
  components: {
    ElProgress,
    ElDialog,
    MapControl,
    // PlainDetail,
    // SolidDetail,
    AudioPlayer,
    MapLocation,
    ElIcon,
    Detail,
    Socials,
  },
  setup() {
    const status = useStatus();
    const isMobile = inject('isMobile');
    const canvasRef = ref(null);
    let printData = reactive({});
    provide('printData', printData);

    let roomData = reactive({});
    let requestAnimation;
    let canvasW;
    let canvasH;
    let doorPos;
    let scene;
    let camera;
    let renderer;
    let controls;
    let items = [];
    let poster;
    let spots = [];
    let resources = [];

    const route = useRoute();

    const router = useRouter();
    const dialog = useDialog(status);
    const { isFull, fullSty, toggleFull } = useFull();

    const { activeItem, setActiveItem, setItems } = useActiveItem();
    provide('activeItem', activeItem);

    let ctrl = useCtrl(setActiveItem);
    provide('ctrl', ctrl);

    const bgm = ref();

    const offsetTop = ref(0);
    const selectionData = reactive([]);

    if (route.name === 'Editor.Preview') {
      const { data } = inject('selection');

      Object.assign(selectionData, data);
    }

    const init = async () => {
      THREE.Object3D.DefaultUp = new THREE.Vector3(0, 0, 1);

      const res = await ExhibService.blueprint({ id: route.params.id });
      if (
        res.code !== 1 ||
        !res.data.blueprint ||
        !res.data.blueprint.constructor.toString().indexOf('Array') < 0
      ) {
        await ElMessageBox.alert('場景內容不存在', { center: true });
        return router.push({ name: 'Home' });
      }

      // print給小地圖用

      const blueprint = JSON.parse(res.data.blueprint);

      if (!blueprint.scene.depth) {
        blueprint.scene.depth = ROOM_HEIGHT;
      }

      blueprint.items = blueprint.items.map((x) => {
        const selectionItem = selectionData.find((y) => x.id === y.id);

        return {
          ...x,
          meta: {
            ...x.meta,
            ...selectionItem,
          },
        };
      });

      Object.assign(printData, blueprint);
      Object.assign(roomData, konva2Three({ ...res.data, blueprint }));

      // console.log(blueprint);
      bgm.value = res.data.bgm;

      try {
        resources = await usePreload({ roomData });
      } catch (error) {
        console.log(error);
      }

      // const f = new FontFace(
      //   'noto',
      //   'url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.woff)'
      // );
      // const font = await f.load();
      // document.fonts.add(font);
      // const fb = new FontFace(
      //   'notoBold',
      //   'url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Bold.woff)'
      // );
      // const fontBold = await fb.load();
      // document.fonts.add(fontBold);

      doorPos = {
        x: roomData.scene.width / 2 - GRID * 8,
        y: (roomData.scene.height / 2) * -1,
      };

      scene = useScene({
        width: roomData.scene.width,
        height: roomData.scene.height,
      });

      const { door } = useOuter({ resources, roomData, scene });

      // 為何呀
      door._type = 'outer';

      const walls = useWall({ resources, roomData, scene });

      useFloor({ resources, roomData, scene });

      useLights({ roomData, scene });

      poster = usePoster({ resources, roomData, scene });

      items = useItems({ resources, roomData, scene });
      setItems(items);

      spots = useSpots({
        resources,
        scene,
        poster,
        doorPos,
        items,
      });

      renderer = useRenderer({ canvasW, canvasH, scene });
      camera = useCamera({ canvasW, canvasH, scene });
      controls = useControls({ camera, renderer, scene });
      ctrl.init(controls, spots);
      const animation = useAnimation({
        spots,
        ctrl,
        roomData,
        isPreview: route.name == 'Editor.Preview',
      });

      animation.start();
      useIntersect({
        scene,
        isMobile,
        offsetTop,
        onClickEntry: animation.enter,
        isFull,
        controls,
        renderer,
        camera,
        spots,
        activeItem,
        dialog,
        ctrl,
        items,
        walls,
        outers: [door],
      });
      const resizeCanvas = (width, height) => {
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
        renderer.render(scene, camera);

        offsetTop.value = canvasRef.value.getBoundingClientRect().top;
      };

      useResizeObserver(canvasRef, (entries) => {
        const entry = entries[0];
        if (!entry) return;
        const { width, height } = entry.contentRect;
        canvasW = width;
        canvasH = height;
        resizeCanvas(width, height);
      });

      requestAnimation = useUpdate({
        status,
        controls,
        dialog,
        renderer,
        camera,
        scene,
      });
      requestAnimation.start();
    };

    onBeforeUnmount(() => {
      if (requestAnimation) {
        requestAnimation.stop();
      }
    });

    onMounted(async () => {
      if (route.name !== 'Editor.Preview') {
        const res = await SelectionService.list({
          id: route.params.id,
        });
        if (res.code !== 1) {
          await ElMessageBox.alert('資料錯誤', { center: true });
          return router.push({ name: 'Editor.Space' });
        } else {
          Object.assign(selectionData, res.data.items);
        }
      }

      init();
    });

    const isSharing = ref(false);
    const toggleIsSharing = () => {
      isSharing.value = !isSharing.value;
    };

    return {
      bgm,
      fullSty,
      isFull,
      toggleFull,
      canvasRef,
      dialog,
      percentage,
      isSharing,
      toggleIsSharing,
    };
  },
};
</script>
