import {
  DirectionalLight,
  DirectionalLightHelper,
  AmbientLight,
  PointLight,
  PointLightHelper,
} from 'three';
import { ROOM_HEIGHT, LIGHT_DIS } from '@/utils/Config';
export default class Light {
  constructor(params) {
    const { width, height, scene, roomData } = params;

    const depth = roomData.scene.depth || ROOM_HEIGHT;
    const topLight = new DirectionalLight(0xffffff, 0.2);
    topLight.position.set(100, -100, 100);
    topLight.target.position.set(0, 0, 0);

    // topLight.position.multiplyScalar(1.3);
    const sLight = new DirectionalLight(0xffffff, 0.2);
    sLight.position.set(-100, -100, 100);
    sLight.target.position.set(0, 0, 0);
    // sLight.position.multiplyScalar(1.3);
    scene.add(sLight);

    const bLight = new DirectionalLight(0xffffff, 0.1);
    bLight.position.set(0, 0, -100);
    bLight.target.position.set(0, 0, 0);
    // sLight.position.multiplyScalar(1.3);
    scene.add(bLight);

    // scene.add(new DirectionalLightHelper(topLight, 50));
    // scene.add(new DirectionalLightHelper(sLight, 50));
    // scene.add(new DirectionalLightHelper(bLight, 50));

    //A end
    var ambient = new AmbientLight(0xffffff, 0.7);
    ambient.position.set(0, 0, 0);
    scene.add(ambient);
    // console.log(height);
    const basic = 16000000
    const ratio = (width * height) / basic
    console.log((width * height) / basic);


    const lightDis = (ratio < 1 ? 1 : ratio) * LIGHT_DIS

    const xCount = Math.floor(width / lightDis);
    const yCount = Math.floor(height / lightDis);
    console.log(xCount);
    console.log(yCount);
    let posX, posY;
    for (let i = 0; i < xCount; i++) {
      const w = width / xCount;
      posX = i * w - width / 2 + w / 2;
      for (let j = 0; j < yCount; j++) {
        const y = height / yCount;
        posY = j * y - height / 2 + y / 2;
        const pointLight = new PointLight(0xdff7f7, 0.4, depth * 0.9);
        pointLight.position.set(posX, posY, depth * 0.75);

        scene.add(pointLight);
      }
      // const sphereSize = 5;
      // const pointLightHelper = new PointLightHelper(pointLight, sphereSize);
      // scene.add(pointLightHelper);
    }
  }
}
