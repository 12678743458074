import {
  WebGLRenderer,
  sRGBEncoding,
  GammaEncoding,
  PCFSoftShadowMap,
} from 'three';
export default class Renderer extends WebGLRenderer {
  constructor(params) {
    const { width, height, containerId } = params;
    super({
      antialias: true,
    });
    this.setClearColor(0xc2deed, 1.0);

    this.shadowMap.enabled = true;
    this.setPixelRatio(window.devicePixelRatio);

    this.shadowMap.type = 2;
    this.setSize(width, height);

    // this.outputEncoding = sRGBEncoding;
    const container = document.getElementById(containerId);
    container.appendChild(this.domElement);
    this._init();
  }
  _init() {
    this.bounds = this.domElement.getBoundingClientRect();
  }
}
