import _ from 'lodash';
import { GRID, ROLE_Z } from '@/utils/Config';
const konva2Three = (data) => {
  if (!data.blueprint) {
    return false;
  }
  let out = _.cloneDeep(data.blueprint);

  const offsetX = data.blueprint.scene.width / 2;
  const offsetY = data.blueprint.scene.height / 2;

  out.poster = data.poster;
  out.bgm = data.bgm;
  out.scene.color = data.color;
  out.walls.forEach((wall) => {
    wall.x = wall.x + wall.width / 2 - offsetX;
    wall.y = (wall.y + wall.height / 2 - offsetY) * -1;
  });

  out.items.forEach((work) => {
    switch (work.rotation) {
      case 0:
      case undefined:
        work.x = work.x + work.width / 2 - offsetX;
        work.y = (work.y + work.height / 2 - offsetY) * -1;
        break;

      case 90:
        work.x = work.x - work.height / 2 - offsetX;
        work.y = (work.y + work.width / 2 - offsetY) * -1;
        break;
      case 180:
      case -180:
        work.x = work.x - work.width / 2 - offsetX;
        work.y = (work.y - work.height / 2 - offsetY) * -1;
        break;
      case -90:
      case 270:
        work.x = work.x + work.height / 2 - offsetX;
        work.y = (work.y - work.width / 2 - offsetY) * -1;
        break;
    }
    work.rotation = work.rotation * -1;
    // work.meta.spotX = work.meta.spotX * 30 + GRID / 2 - offsetX;
    // work.meta.spotY =
    //   (work.meta.spotY * 30 + GRID / 2 - offsetY) * -1;
    work.meta.spotX = work.meta.spotX - offsetX;
    work.meta.spotY = (work.meta.spotY - offsetY) * -1;
  });

  return out;
};
export default konva2Three;
