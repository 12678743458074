<template>
  <div class="flex flex-wrap px-4">
    <ShareNetwork
      v-for="network in SOCIALS"
      :network="network.network"
      :key="network.network"
      :style="{ backgroundColor: network.color }"
      :url="data.url"
      :title="data.title"
      :description="data.description"
      :quote="data.quote"
      :hashtags="data.hashtags"
      :twitterUser="data.twitterUser"
    >
      <i :class="network.icon"></i>
      <span>{{ network.name }}</span>
    </ShareNetwork>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';
import ShareNetwork from 'vue-social-sharing/src/share-network';
import { SOCIALS } from '@/utils/Config';
import { useRoute } from 'vue-router';

export default {
  components: {
    ShareNetwork,
  },
  setup(props) {
    const route = useRoute();

    const data = reactive({
      url: `${location.protocol}//${location.host}/exhib/${route.params.id}/${route.params.slug}`,
      title: 'Say hi to 國立歷史博物館',
      description: 'Say hi to 國立歷史博物館',
      // quote: "The hot reload is so fast it's near instant. - Evan You",
      // hashtags: 'vuejs,vite,javascript',
    });

    return {
      data,
      SOCIALS,
    };
  },
};
</script>
<style lang="scss" scoped>
:deep(a) {
  @apply text-white px-4 py-1 inline-block mb-4 mx-2;
}
</style>
