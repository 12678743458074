// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GRID, ROLE_Z, ROOM_COLOR } from '@/utils/Config';
// import { Vector2, Vector3, MOUSE, Raycaster } from 'three';
import * as THREE from 'three';
import CameraControls from 'camera-controls';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
CameraControls.install({ THREE: THREE });

var lookDirection = new THREE.Vector3();

// const raycaster = new Raycaster();

export default class Controls extends CameraControls {
  constructor(params) {
    const { camera, dom, scene } = params;
    super(camera, dom);
    this.camera = camera;

    this.enableZoom = true;
    if (this.enableZoom) {
      this.touches.one = CameraControls.ACTION.TOUCH_ZOOM;
      this.mouseButtons.wheel = CameraControls.ACTION.ZOOM;
      this.minZoom = 1;
      this.maxZoom = 2;
    }

    this.enableDamping = true;
    this.dampingFactor = 0.8;
    this.minDistance = this.maxDistance = 1;
    this.azimuthRotateSpeed = -0.2; // negative value to invert rotation direction
    this.polarRotateSpeed = -0.1; // negative value to invert rotation direction

    this._spot = null;

    this.clock = new THREE.Clock();

    this._init();
  }
  _init() {
    // this.setLookAt(2600, 600, ROLE_Z, 0, 500, 0, false);
    // this.setTarget(2600, 2600, 2600, false);
    // this.object.setPosition(2600, 2600, 2600, false);
  }
  transitionend() {
    this.fixTarget();
    this.enabled = true;
  }

  fixTarget() {
    this._camera.getWorldDirection(lookDirection);
    const pos = this.getPosition().add(lookDirection.multiplyScalar(10));
    this.setTarget(pos.x, pos.y, pos.z);
  }
  lookAtSpot(spot, dur = 2000) {
    this.enabled = false;
    return new Promise((resolve) => {
      var look = new TWEEN.Tween(
        this.getTarget().add(lookDirection.multiplyScalar(5))
      )
        .to({ ...spot.lookAt, z: ROLE_Z }, dur)
        .easing(TWEEN.Easing.Quartic.InOut)
        .onUpdate((e) => {
          this.setTarget(e.x, e.y, e.z, false);
        })
        .onComplete((e) => {
          this.transitionend();
          resolve(e);
        })
        .start();
    });
  }
  resetZoom() {
    if (this.enableZoom) {
      this.zoomTo(1, true);
    }
  }
  toggleSpot(spot) {
    if (!spot.toggle) return;
    if (this._spot) {
      this._spot.toggle();
      this._spot = null;
    }

    this._spot = spot;
    spot.toggle();
  }

  walkToSpot(spot) {
    this.enabled = false;
    const { x, y } = spot.position;
    const { x: lx, y: ly, z: lz } = spot.lookAt;

    this.toggleSpot(spot);

    return this.walkTo(x, y, ROLE_Z, lx, ly, lz);
  }
  moveToSpot(spot) {
    this.resetZoom();
    this.enabled = false;
    const { x, y } = spot.position;
    const { x: lx, y: ly, z: lz } = spot.lookAt;

    this.toggleSpot(spot);

    const setLookAt = this.setLookAt(x, y, ROLE_Z, lx, ly, lz, true);

    setTimeout(() => {
      this.transitionend();
    }, 1000);
    return setLookAt;
  }

  walkTo(targetX, targetY, targetZ, lookAtX, lookAtY, lookAtZ, speed) {
    this.resetZoom();
    return new Promise((resolve) => {
      const dur = speed
        ? speed
        : this.getPosition().distanceTo({
          x: targetX,
          y: targetY,
          z: ROLE_Z,
        });

      // cl
      var walk = new TWEEN.Tween(this.getPosition())
        .to({ x: targetX, y: targetY }, dur * 2)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onUpdate((e) => {
          this.setPosition(e.x, e.y, ROLE_Z, false);
        })
        .onComplete(() => {
          this.transitionend();

          resolve();
        })
        .start();

      this._camera.getWorldDirection(lookDirection);

      var look = new TWEEN.Tween(
        this.getTarget().add(lookDirection.multiplyScalar(dur))
      )
        .to({ x: lookAtX, y: lookAtY, z: lookAtZ }, dur)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onUpdate((e) => {
          this.setTarget(e.x, e.y, e.z, false);
        })
        .onComplete((e) => { })
        .start();
    });
  }

  updateTWEEN() {
    TWEEN.update();
  }
  updateControl(status) {
    TWEEN.update();
    const delta = this.clock.getDelta();
    const hasControlsUpdated = this.update(delta);
    status.set(hasControlsUpdated ? 'controling' : null);

    return hasControlsUpdated;
  }
}
