import { BoxGeometry, Mesh, MeshPhongMaterial, DoubleSide } from 'three';
import { GRID, ROLE_Z, ROOM_COLOR, FLOOR_HEIGHT } from '@/utils/Config';

import FaceMaterial from './utils/FaceMaterial';
export default class Roof extends Mesh {
  constructor(params) {
    const { x, y, width, height, sceneDepth, color, texture } = params;
    super();
    this.size = { w: width - 0.1, h: height, d: GRID };

    this.geometry = new BoxGeometry(
      this.size.w - 5,
      this.size.h - 5,
      this.size.d
    );

    this.material = FaceMaterial({
      texture,
      color,
      size: this.size,
      faces: ['d'],
    });

    this._type = 'wall';
    this.position.x = x;
    this.position.y = y;
    this.position.z = sceneDepth + GRID / 2;
    // this.castShadow = true;
    this._type = 'roof';
    this.receiveShadow = true;
    this.castShadow = true;
  }
}
