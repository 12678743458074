import {
  Vector2,
  VertexColors,
  BoxGeometry,
  Mesh,
  MeshBasicMaterial,
  MeshPhongMaterial,
  RepeatWrapping,
  Math as _Math,
} from 'three';

export default ({
  texture,
  color = '#ffffff',
  size = { w: 30, h: 30, d: 30 },
  faces = ['r', 'l', 't', 'b', 'u', 'd'],
  ratio = 100,
}) => {
  const temps = ['r', 'l', 't', 'b', 'u', 'd'];
  let materials = Array(6).fill(null);
  if (texture) {
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.repeat.set(3, 3);
  }

  // ['r', 'l', 't', 'b','u','d'];

  temps.forEach((x, i) => {
    let opt;

    if (faces.includes(x) && texture) {
      const map = texture.clone();
      if (x === 'r' || x === 'l') {
        map.repeat.set(size.h / ratio, size.d / ratio);
        map.rotation = _Math.degToRad(90);
      } else if (x === 't' || x === 'b') {
        map.repeat.set(size.w / ratio, size.d / ratio);
      } else if (x === 'u' || x === 'd') {
        map.repeat.set(size.w / ratio, size.h / ratio);
      }

      map.needsUpdate = true;
      opt = {
        // color,
        map: map,
      };
    } else {
      opt = {
        color,
      };
    }
    materials[i] = new MeshPhongMaterial({
      ...opt,
      shininess: 50,
    });
  });

  return materials;
};
