import { ref } from 'vue';
export default () => {
  const mode = ref('playing');
  console.log('mode:' + mode.value);
  const set = (_mode) => {
    mode.value = _mode || 'playing';

    // console.log('mode:' + mode.value);
  };

  return { status, set };
};
