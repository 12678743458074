import * as THREE from 'three';
import {
  DoubleSide,
  Mesh,
  BoxGeometry,
  SpotLight,
  SpotLightHelper,
  MeshPhongMaterial,
  MeshBasicMaterial,
  PlaneGeometry,
  Vector2,
  PlaneBufferGeometry,
  Sprite,
  SpriteMaterial,
  Math as _Math,
} from 'three';
import Item from './Item';
import ProfiledContourGeometry from './utils/ProfiledContourGeometry';
import ProfiledContourMMgeometry from './utils/ProfiledContourMMgeometry';
import ProfiledContourUV from './utils/ProfiledContourUV';
import { ElMessageBox } from 'element-plus';
import { GRID, ROLE_Z, FRAME_WIDTH } from '@/utils/Config';
import { THREEVideoPlayer } from '@/modules/room/utils/three-video-player/three-video-player-class.js';
// import videoSrc from '@/modules/room/utils/three-video-player/sample-mp4-file.mp4';
import IconVideoMark from './utils/three-video-player/play_button_alpha.jpg';
import IconInfo from '@/assets/info.png';
// const m3u8 =
//   'https://cloudflarestream.com/73a4ac2a9b6e2e854b3ac6ee4c068112/manifest/video.m3u8';

export default class Plain extends Item {
  constructor(params) {
    const {
      id,
      resource,
      x,
      y,
      width,
      height,
      rotation,
      meta,
      scene,
      shadowMap,
      frame,
    } = params;
    super(params);

    this._type = 'plain';
    this._frame = frame;
    this.addFrame();
    this.shadowMap = shadowMap;
    this.shadow();

    // console.log(this.meta);

    if (this.meta.blank === 'No' && this.meta.mp4) {
      this.addVideo();
    }

    if (this.meta.videouri || this.meta.mp4) {
      this.addVideoMark();
    }
    if (
      this.meta.videouri &&
      !this.meta.videouri.startsWith('//imedia.culture.tw')
    ) {
      this.addVideoLink();
    }
  }

  addVideoLink() {
    let uri = this.meta.videouri;

    // if (this.meta.videouri.startsWith('//imedia.culture.tw')) {
    //   if (!this.meta.link2) return;
    //   uri = this.meta.link2;
    // }

    const material = new SpriteMaterial({
      map: new THREE.TextureLoader().load(IconInfo),
    });
    const sprite = new Sprite(material);

    sprite.position.set((this.meta.l / 2 + 30) * -1, -5, this._obj.position.z);
    sprite.width = 80;
    sprite._id = this._id;
    sprite.scale.set(20, 20, 20);
    this.add(sprite);

    sprite._onClick = () => {
      ElMessageBox.confirm('確定開啟影片網站?', { center: true }).then(() => {
        window.open(uri);
      });
    };
  }

  render() {
    this.meta.w = 5;
    this.resource.rotation = Math.PI;
    this.resource.center = new Vector2(0.5, 0.5); // center of texture.
    const material = new MeshPhongMaterial({
      color: 0x333333,
      shininess: 70,
    });

    let materials = Array(6).fill(material);
    materials[2] = new MeshPhongMaterial({
      map: this.resource,
      color: 0xffffff,
      shininess: 70,
    });

    const geometry = new BoxGeometry(this.meta.l, this.meta.w, this.meta.h);

    const item = new Mesh(geometry, materials);

    item.position.set(
      0 - this.meta.offsetX,
      -(GRID / 2 - this.meta.w) - 3,
      ROLE_Z - this.meta.offsetY
    );

    this.add(item);

    return item;
  }

  addFrame() {
    if (!this._frame) return;
    const texture = this._frame.data.texture;
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.offset.set(0, 0);

    texture.rotation = Math.PI / 2;

    texture.needsUpdate = true;

    const mat = new THREE.MeshPhongMaterial({
      map: texture,
      color: 0xffffff,
      side: THREE.DoubleSide,

      specularMap: texture,
      // wireframe: true,
    });
    const matd = new THREE.MeshPhongMaterial({
      map: texture,
      color: 0x999999,
      side: THREE.DoubleSide,
      // wireframe: true,
    });
    var materials = [matd, mat, mat, mat];
    const FRAME_WIDTH_2 = FRAME_WIDTH / 2;
    let shape = [
      -FRAME_WIDTH_2,
      FRAME_WIDTH_2,
      FRAME_WIDTH_2,
      FRAME_WIDTH_2,
      FRAME_WIDTH_2,
      -FRAME_WIDTH_2,
      -FRAME_WIDTH_2,
      -FRAME_WIDTH_2,
    ];

    var contour = [
      0,
      0,
      -(this.meta.l + 8),
      0,
      -(this.meta.l + 8),
      -(this.meta.h + 8),
      0,
      -(this.meta.h + 8),
    ];

    const frame = ProfiledContourUV(shape, contour, materials, false, true);
    frame.position.set(
      0 - this.meta.offsetX + this.meta.l / 2 + 4,
      -(GRID / 2 - this.meta.w),
      ROLE_Z - this.meta.offsetY + this.meta.h / 2 + 4
    );

    frame.rotation.x = _Math.degToRad(90);
    this.add(frame);
  }
  addVideoMark() {
    this.videoMark = new THREE.Mesh(
      new PlaneBufferGeometry(30, 30),
      new MeshBasicMaterial({
        color: 0xffffff,
        alphaMap: new THREE.TextureLoader().load(IconVideoMark),
        alphaTest: 0.8,
        side: THREE.DoubleSide,
      })
    );
    this.videoMark.position.set(
      this._obj.position.x,
      -(GRID / 2 - this.meta.w) + 5,
      this._obj.position.z
    );
    this.videoMark.rotation.x = _Math.degToRad(90);
    this.videoMark.rotation.z = _Math.degToRad(180);
    this.add(this.videoMark);
  }

  addVideo() {
    this.videoPlayer = new THREEVideoPlayer(this.meta.l, this.meta.h, {});
    this.videoPlayer.position.set(
      this._obj.position.x,
      -(GRID / 2 - this.meta.w) + 2,
      this._obj.position.z
    );
    this.videoPlayer.visible = false;

    this.add(this.videoPlayer);
  }
  hitten() {
    if (this.videoPlayer) {
      this.videoMark.visible = false;
      this.videoPlayer.setSource(window.$filters.uploadUrl(this.meta.mp4));
      this.videoPlayer.visible = !this.videoPlayer.visible;
    }
    this.spotLight.visible = true;
    this.toggleLight(3);
  }
  leave() {
    if (this.videoPlayer) {
      this.videoMark.visible = true;
      this.videoPlayer.clearSource();
      this.videoPlayer.visible = !this.videoPlayer.visible;
    }
    this.spotLight.visible = false;
  }

  shadow() {
    const shadow = new Mesh();

    shadow.geometry = new PlaneGeometry(this.meta.l, 20);
    shadow.material = new MeshPhongMaterial({
      side: DoubleSide,
      color: '#000',
      map: this.shadowMap,
      opacity: 0.3,
      transparent: true,
    });

    shadow.position.set(
      this._obj.position.x,
      -13,
      this._obj.position.z - this.meta.h / 2 - 10
    );
    shadow.rotation.x = _Math.degToRad(90);
    this.add(shadow);
  }
}
