import * as THREE from 'three';

import Plain from '@/modules/room/Plain';
import Solid from '@/modules/room/Solid';
import Intro from '@/modules/room/Intro';
import { GRID, ROLE_Z } from '@/utils/Config';
export default ({ resources, roomData, scene }) => {
  const shadowMap = resources.find((x) => x.id === 'shadow');
  const shadowDropMap = resources.find((x) => x.id === 'shadowDrop');
  const items = [];

  roomData.items.forEach((el) => {
    let item;
    const _resource = resources.find((x) => x.id === el.meta.id);
    if (el.meta.type === 4) {
      item = new Intro({
        scene,
        resource: _resource?.data,
        id: el.meta.id,
        x: el.x,
        y: el.y,
        width: el.width,
        height: el.height,
        rotation: el.rotation,
        sceneDepth: roomData.scene.depth,

        meta: { ...el.meta },
      });
    } else if (el.meta.type === 2 || el.meta.type === 5) {
      // const _resource = resources.find((x) => x.id === el.meta.id);
      if (!_resource) return;

      if (!el.meta.h) {
        el.meta.h =
          (el.meta.l * _resource.data.image.height) /
          _resource.data.image.width;
      }

      let frame;
      if (el.meta.extra && el.meta.extra.frame) {
        frame = resources.find((x) => x.id === el.meta.extra.frame);
      }

      item = new Plain({
        scene,
        id: el.meta.id,
        resource: _resource?.data,
        x: el.x,
        y: el.y,
        width: el.width,
        height: el.height,
        sceneDepth: roomData.scene.depth,
        rotation: el.rotation,
        shadowMap: shadowMap?.data,
        meta: { ...el.meta },
        frame,
      });
    } else if (el.meta.type === 3) {
      // const _resource = resources.find((x) => x.id === el.meta.id);

      if (!_resource) return;

      item = new Solid({
        scene,
        id: el.meta.id,
        resource: _resource.data,
        x: el.x,
        y: el.y,
        width: el.width,
        height: el.height,
        rotation: el.rotation,
        sceneDepth: roomData.scene.depth,
        shadowDropMap: shadowDropMap?.data,
        baseColor: el.meta?.extra?.color ? el.meta.extra.color : el.meta.color,
        baseTexture: el.meta?.extra?.texture
          ? resources.find((x) => x.id === el.meta.extra.texture).data
          : null,
        meta: { ...el.meta },
      });
    }
    scene.add(item);
    items.push(item);
  });

  return items;
};
