import { ref, watch, reactive } from 'vue';
import { Scene } from 'three';
import { ROLE_Z } from '@/utils/Config';

export default (setActiveItem, scene) => {
  let controls = null;
  let spots = null;
  const moving = ref(false);
  const list = reactive([]);
  const state = reactive({
    activeIdx: 0,
  });
  const activeIdx = ref(0);

  const init = (_controls, _spots) => {
    controls = _controls;
    spots = _spots;
    setList(_spots);
  };

  const setList = () => {
    Object.assign(
      list,
      spots.map((x) => {
        return { title: x.title, id: x._id, cover: x.cover, active: false };
      })
    );
  };

  const updateActive = () => {
    list.forEach((x) => {
      x.active = x.id === activeIdx.value;
    });
  };

  const getCheckObj = (param) => {
    if (param === null) return;
    if (typeof param === 'object') {
      return param;
    }
    let idx;
    if (typeof param === 'string') {
      idx = spots.findIndex((x) => x._id === param);
    } else {
      idx = param;
    }

    return spots[idx] ? spots[idx] : spots[0];
  };

  const next = () => {
    if (activeIdx.value >= list.length - 1) return;
    let idx = activeIdx.value + 1;
    goto(list[idx].id);
  };

  const prev = () => {
    if (activeIdx.value === 0) return;
    let idx = activeIdx.value - 1;
    goto(list[idx].id);
  };

  const lookAtSpot = (spot, dur) => {
    return controls.lookAtSpot(spot, dur);
  };

  const goto = (param) => {
    updateActive();
    const spot = getCheckObj(param);

    activeIdx.value = list.findIndex((x) => spot._id === x.id);
    return move(spot);
  };

  const walkTo = (param) => {
    const spot = getCheckObj(param);

    activeIdx.value = list.findIndex((x) => spot._id === x.id);
    updateActive();
    return walk(param);
  };
  const lookAround = async ({ targetSpot, scene }) => {
    await lookAtSpot({ lookAt: { x: 0, y: scene.height / -2 } }, 2000);
    await lookAtSpot({ lookAt: { x: scene.width / 2, y: 0 } }, 3000);

    const res = await lookAtSpot(
      targetSpot || { lookAt: { x: 0, y: 0 } },
      2000
    );
    return res;
  };

  const set = (pos) => {
    controls.setPosition(pos.x, pos.y, pos.z);
  };

  const toggleSpot = (spot) => {
    spots.forEach((x) => {
      const dis = x.position.distanceTo(spot.position);
      x.visible = dis < 1200;
    });
  };
  const move = (spot) => {
    setActiveItem(spot._id);
    setMe({ ...spot.position });
    const moveToSpot = controls.moveToSpot(spot);
    toggleSpot(spot);
    return moveToSpot;
  };

  const walk = (spot) => {
    setActiveItem(spot._id);
    setMe({ ...spot.position });
    toggleSpot(spot);
    return controls.walkToSpot(spot);
  };
  const setMe = ({ x, y }) => {
    me.x = x;
    me.y = y;
  };
  const me = reactive({
    x: 0,
    y: 0,
  });

  return {
    set,
    me,
    init,
    activeIdx,
    spotList: list,
    next,
    prev,
    goto,
    walkTo,
    lookAtSpot,
    lookAround,
  };
};
