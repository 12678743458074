import * as THREE from 'three';
import Shadow from '@/modules/room/Shadow';
import Renderer from '@/modules/room/Renderer';
import { WEBGL } from 'three/examples/jsm/WebGL';
import { GRID, ROLE_Z } from '@/utils/Config';
export default ({ canvasW, canvasH, scene }) => {
  let renderer;
  if (WEBGL.isWebGLAvailable()) {
    renderer = new Renderer({
      containerId: 'room',
      width: canvasW,
      height: canvasH,
    });
  } else {
    console.log('not support');
  }
  return renderer;
};
