import {
  DoubleSide,
  Mesh,
  Group,
  PlaneGeometry,
  SpotLight,
  SpotLightHelper,
  MeshPhongMaterial,
  Texture,
  Vector3,
  Vector2,
  MeshBasicMaterial,
  RepeatWrapping,
  Math as _Math,
} from 'three';

import { GRID, ROLE_Z } from '@/utils/Config';
import Item from './Item';
export default class Intro extends Item {
  constructor(params) {
    const {
      id,
      x,
      y,
      width,
      height,
      sceneDepth,
      rotation,
      meta,
      scene,
      resource,
    } = params;
    super(params);
    this._type = 'intro';
  }
  setlookAtPos() {
    this.lookAtPos = {
      ...this._obj.getWorldPosition(new Vector3()),
      z: this.sceneDepth / 2 - this.meta.offsetY - GRID * 3,
    };
  }
  render() {
    let h, w;
    let texture;
    if (this.resource) {
      texture = this.resource;
      w = this.width;
      h = (this.width * texture.image.height) / texture.image.width;
    } else {
      let scale = 3;
      var canvas = document.createElement('canvas');
      let offsetText = 16 * scale;
      canvas.width = this.width * scale;
      canvas.height = this.sceneDepth * scale;
      var context = canvas.getContext('2d');
      context.font =
        'bold ' +
        16 * scale +
        'pt "notoBold","黑體-繁", "Heiti TC", "Heiti SC", "微軟正黑體", "Microsoft JhengHei"';

      context.strokeStyle = 'black';
      context.textBaseline = 'middle';
      context.fillStyle = this.meta.color;
      context.fillText(this.meta.title, 0, offsetText);
      const size = 6;
      context.font =
        size * scale +
        'pt "noto","黑體-繁", "Heiti TC", "Heiti SC", "微軟正黑體", "Microsoft JhengHei"';

      let contentH = 120;
      if (this.meta.excerpt) {
        let total = context.mlFillText(
          this.meta.excerpt.trim(),
          0,
          offsetText + 60,
          this.meta.l * scale,
          600 * scale,
          'top',
          'left',
          size * 2.5 * scale
        );
        contentH += parseInt(total);
      }
      var imageData = context.getImageData(0, 0, canvas.width, contentH);
      var newCan = document.createElement('canvas');
      newCan.width = canvas.width;
      newCan.height = contentH;
      var newCtx = newCan.getContext('2d');
      newCtx.putImageData(imageData, 0, 0);
      w = canvas.width / scale;
      h = contentH / scale;
      texture = new Texture(newCan);
    }
    var material = new MeshBasicMaterial({
      map: texture,
    });
    material.transparent = true;
    // material.depthTest = true;
    material.map.needsUpdate = true;

    var item = new Mesh(new PlaneGeometry(w, h), material);
    item.overdraw = true;
    // box.doubleSided = true;
    item.position.set(
      ((this.width - this.meta.l) / 2) * -1 - this.meta.offsetX,
      -12,
      ROLE_Z - h / 2 - this.meta.offsetY + 30
    );

    item.rotation.x = _Math.degToRad(90);
    item.rotation.y = _Math.degToRad(180);

    this.add(item);
    return item;
  }
}
