import * as THREE from 'three';

import Controls from '@/modules/room/Control';
import { GRID, ROLE_Z } from '@/utils/Config';
export default ({ camera, renderer, scene }) => {
  let controls = new Controls({
    camera,
    dom: renderer.domElement,
    scene,
  });
  return controls;
};
