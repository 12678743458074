import { PerspectiveCamera } from 'three';

import { CSG } from 'three-csg-ts';
export default class Camera extends PerspectiveCamera {
  constructor(params) {
    const { aspect } = params;
    super(55, aspect, 1, 20000);
    this.up.set(0, 0, 1);
    const EPS = 1e-5;
    // this.position.set(0, EPS, 150);

    this.updateProjectionMatrix();

    // this.position.set(1e-5, 0, 0);
  }
}
