import { ref } from 'vue';

export default () => {
  const isFull = ref(false);
  const fullSty = 'full-block fixed top-0 left-0 z-50 w-screen h-screen z-50';
  const toggleFull = () => {
    isFull.value = !isFull.value;
  };

  return {
    isFull,
    fullSty,
    toggleFull,
  };
};
