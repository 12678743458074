import {
  DoubleSide,
  Mesh,
  BoxGeometry,
  SpotLight,
  SpotLightHelper,
  MeshLambertMaterial,
  MeshPhongMaterial,
  Group,
  MeshBasicMaterial,
  Vector2,
  PointLight,
  PointLightHelper,
  Math as _Math,
} from 'three';

import { GRID, ROLE_Z, ROOM_HEIGHT } from '@/utils/Config';
export default class Poster extends Group {
  constructor(params) {
    const { id, texture, x, y, l, w, h, scene } = params;
    super();
    this._id = id;
    this.scene = scene;
    this.position.x = x;
    this.position.y = y;
    this.position.z = ROOM_HEIGHT / 2;

    this.render(l, w, h, texture);
    this.light();
    // this._init();
  }

  render(l, w, h, texture) {
    texture.rotation = Math.PI;
    texture.center = new Vector2(0.5, 0.5); // center of texture.
    const material = new MeshPhongMaterial({
      color: 0x4682b4,
      side: DoubleSide,
      shininess: 70,
    });
    let materials = Array(6).fill(material);
    materials[2] = new MeshPhongMaterial({
      map: texture,
      color: 0xffffff,
      shininess: 50,
      reflectivity: 0,
    });
    const geometry = new BoxGeometry(l, 5, h);
    const cube = new Mesh(geometry, materials);

    cube.receiveShadow = true;
    cube.material.map.needsUpdate = true;
    this.rotation.z = _Math.degToRad(180);

    this.add(cube);
  }
  light() {
    const light = new PointLight(0xdff7f7, 0.5, 3000);
    light.position.set(-400, 500, 220);
    // light.castShadow = true;
    // light.shadow.mapSize.width = 1024;
    // light.shadow.mapSize.height = 1024;
    light.shadow.camera.far = 20;
    this.add(light);

    const sphereSize = 5;
    // const pointLightHelper = new PointLightHelper(light, sphereSize);
    // this.scene.add(pointLightHelper);
  }
}
