<template>
  <div class="flex flex-col w-full h-full">
    <div class="flex-grow h-0 relative">
      <swiper
        :modules="modules"
        class="h-full bg-gray-200"
        :zoom="true"
        :space-between="0"
        @swiper="onSwiper"
        @zoomChange="onZoomChange"
        :watchSlidesProgress="true"
        :style="{
          '--swiper-pagination-color': '#fff',
        }"
      >
        <swiper-slide>
          <div class="swiper-zoom-container h-full w-full" data-swiper-zoom="4">
            <img
              class="w-full h-full object-contain"
              :src="$filters.uploadUrl(pic)"
              alt=""
            />
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="px-4 py-4 flex-none">
      <div class="py-2 flex">
        <a href="#" @click.prevent="toggleZoom" class="inline-flex">
          <i class="mr-2">
            <img src="@/assets/zoom-in.svg" alt="" />
          </i>
          放大或縮小上方圖片
        </a>
        <a
          href="#"
          class="ml-auto"
          @click.prevent="
            $modal.toggle({
              component: 'Visit',
            })
          "
        >
          <i class="">
            <img src="@/assets/howto.svg" alt="" />
          </i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Zoom } from 'swiper';

// import { ElImage } from 'element-plus';
export default {
  props: {
    video: {
      type: String,
    },
    pic: {
      type: String,
    },
  },
  components: { Swiper, SwiperSlide },
  setup(props) {
    const swiperInstance = ref();

    const zoom = ref(false);
    watch(zoom, (zoom) => {
      swiperInstance.value.allowTouchMove = !zoom;
    });

    const onSwiper = (swiper) => {
      swiperInstance.value = swiper;
      setTimeout(() => {
        swiperInstance.value.update();
      }, 1500);
    };
    const onZoomChange = (e, scale) => {
      zoom.value = scale > 1;
    };

    const toggleZoom = () => {
      if (zoom.value) {
        swiperInstance.value.zoom.out();
      } else {
        swiperInstance.value.zoom.in();
      }
    };

    // });
    return {
      modules: [Zoom],
      toggleZoom,
      zoom,

      onSwiper,

      onZoomChange,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-image) {
  img {
    @apply object-contain;
  }
}
.gallery {
  height: calc(100% - 100px);
}
</style>
