import * as THREE from 'three';
import { GRID, ROLE_Z } from '@/utils/Config';
import { nextTick } from 'vue';

export default ({
  scene,
  isMobile,
  offsetTop,
  onClickEntry,
  controls,
  isFull,
  renderer,
  camera,
  spots,
  activeItem,
  dialog,
  ctrl,
  items,
  walls,
  outers,
}) => {
  const raycaster = new THREE.Raycaster();
  // 排除展台
  // const _items = items.map((x) => {
  //   return x._type === 'solid' ? x.children[0] : x;
  // });
  const raycastList = [];
  // const _raycastList = [...outers, ...walls, ...spots, ...items];
  //  改成全放進去
  scene.traverse((c) => {
    if ((c.isMesh || c.isSprite) && !c.disableRaycast) {
      raycastList.push(c);
    }
  });

  const onClick = (event) => {
    if (!controls.enabled) return;
    // var dom = document.getElementById('room');
    // const top = dom.getBoundingClientRect().top;
    let mouse;
    // 不知為何 上下放大 click 座標部會更新
    mouse = {
      x:
        ((event.clientX - renderer.bounds.left) /
          renderer.domElement.clientWidth) *
        2 -
        1,
      y:
        -(
          (event.clientY - offsetTop.value) /
          renderer.domElement.clientHeight
        ) *
        2 +
        1,
    };

    raycaster.setFromCamera(mouse, camera);

    let intersectSpots = raycaster.intersectObjects(raycastList, false);
    console.log(intersectSpots);

    if (intersectSpots.length > 0) {
      const type = intersectSpots[0]?.object._type;
      if (
        type === 'roof' ||
        type === 'floor' ||
        type === 'wall' ||
        type === 'outer'
      ) {
        return;
      }

      // 目前給影片外連專用
      console.log(typeof intersectSpots[0].object._onClick);
      if (
        intersectSpots[0].object._onClick &&
        typeof intersectSpots[0].object._onClick === 'function'
      ) {
        intersectSpots[0].object._onClick();
        return;
      }
      const intersect = intersectSpots[0].object.parent;
      //  // 點作品的話  去找同樣的id的spot 點吧 如果是item 要把她轉化成spot
      if (activeItem && activeItem.item && activeItem.id === intersect._id) {
        // 只好這樣 不然控步道寬度
        let attrs = {
          center: true,
          fullscreen: true,
          'custom-class': 'is-headerless',
        };

        //要移到物件
        if (activeItem.item.meta.type === 4) {
          attrs = {
            ...attrs,
            fullscreen: isMobile,
            width: isMobile ? '100%' : '600px',
          };
        } else if (activeItem.item.meta.type == 5) {
          if (activeItem.item.videoPlayer) {
            if (!activeItem.item.videoPlayer.isPaused()) {
              activeItem.item.videoPlayer.pause();
            } else if (activeItem.item.videoPlayer.canPlay()) {
              activeItem.item.videoPlayer.play();
            }
            return;
          }
        }

        dialog.toggle({
          component: 'Detail',
          attrs,
        });
      } else {
        const spot = intersect?.meta?.type
          ? spots.find((x) => x._id === intersect._id)
          : intersect;
        if (!spot) return;

        ctrl.walkTo(spot).then((res) => {
          // 硬加在這

          if (spot._id === 'entry') {
            onClickEntry();
          }
        });
      }
    }
  };
  renderer.domElement.addEventListener('mousedown', onClick.bind(this), true);
};
