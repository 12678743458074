import * as THREE from 'three';

import Spot from '@/modules/room/Spot';
import { GRID, ROLE_Z } from '@/utils/Config';
export default ({ resources, scene, poster, doorPos, items }) => {
  let _v3 = new THREE.Vector3();
  const spots = [];
  let icon;
  if (poster) {
    const posterPos = { ...poster.getWorldPosition(_v3) };
    icon = resources.find((x) => x.id === 'iconStart');
    const starter = new Spot({
      id: 'start',
      title: '歡迎區',
      x: poster.position.x,
      y: poster.position.y - 800,
      lookAt: { ...posterPos, x: posterPos.x + 300 },
      texture: icon?.data,
      visible: true,
    });

    scene.add(starter);
    spots.push(starter);
  }
  icon = resources.find((x) => x.id === 'iconEntry');
  const entry = new Spot({
    id: 'entry',
    title: '入口',
    x: doorPos.x,
    y: doorPos.y + 60,
    z: 50,
    // lookAt: !items.length ? { x: 0, y: 0 } : { ...items[0].lookAtPos },

    lookAt: { x: doorPos.x, y: doorPos.y + 100, z: ROLE_Z },
    texture: icon?.data,
    offset: { x: -60, y: -180, z: 0 },
    size: 160,
    visible: true,
  });

  scene.add(entry);
  spots.push(entry);

  items.forEach((x) => {
    icon = resources.find((y) => y.id === 'icon' + x.meta.type);

    const spot = new Spot({
      id: x._id,
      title: x.meta.title,
      x: x.meta.spotX,
      y: x.meta.spotY,
      lookAt: { ...x.lookAtPos },
      type: x.meta.type,
      texture: icon?.data,
      visible: false,
      // item: x,
    });
    scene.add(spot);
    spots.push(spot);
  });

  return spots;
};
