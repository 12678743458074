<template>
  <a href="#" @click.prevent="toggle" class="ml-auto px-2">
    <img
      v-if="!isPlaying"
      class="invert"
      src="@/assets/audio-stop.svg"
      alt=""
    />
    <img v-else src="@/assets/audio.svg" alt="" />
  </a>

  <audio ref="audioRef" id="audio" :src="$filters.uploadUrl(url)" loop></audio>
</template>

<script>
import { ref } from 'vue';
export default {
  props: {
    url: {
      type: String,
    },
  },
  setup() {
    const audioRef = ref(null);

    const isPlaying = ref(false);
    const toggle = () => {
      isPlaying.value = !isPlaying.value;
      if (isPlaying.value) {
        audioRef.value.play();
      } else {
        audioRef.value.pause();
      }
    };
    return { audioRef, isPlaying, toggle };
  },
};
</script>

<style></style>
