import { reactive, inject, watch } from 'vue';

import Wall from '@/modules/editor/Wall';
import Work from '@/modules/editor/Work';

import Konva from 'konva';
import { GRID, STAGE_SIZE } from '@/utils/Config';
import { getCenter } from '@/utils/Utils';
import _ from 'lodash';

export default (data, activeItem) => {
  let stage;
  let radius = 24;

  let items = [];
  let circle;
  let grid;
  let layer;
  let scene = {
    width: 0,
    height: 0,
  };

  let gridSize = GRID;

  const { me, goto } = inject('ctrl');
  watch(me, (val) => {
    const pos = convertMe(val);
    circle.x(pos.x);
    circle.y(pos.y);
  });
  const updateSelected = (val) => {
    const idx = items.forEach((x) => {
      x.fill(x.attrs._id == val.id ? '#000' : '#fff');
    });
  };
  watch(activeItem, (val) => {
    updateSelected(val);
  });

  const createWall = (params) => {
    const item = new Wall({
      ...params,
      stage: stage,
      layer: layer,
      simple: true,
    });
  };
  const convertMe = (pos) => {
    return {
      x: pos.x + scene.width / 2,
      y: pos.y * -1 + scene.height / 2,
    };
  };
  const createItem = (params) => {
    const pos = getCenter(
      params.config.x,
      params.config.y,
      params.config.width,
      params.config.height,
      params.config.rotation
    );

    const item = new Konva.Circle({
      _id: params.config.meta.id.toString(),
      idx: params.config.meta.idx,
      radius: radius,
      fill: '#FFFFFF',
    });

    if (pos) {
      item.position({
        x: pos.x,
        y: pos.y,
      });
    }
    items.push(item);
    item.on('mousedown tap', (e) => {
      goto(e.target.attrs.idx);
    });

    layer.add(item);
  };
  const initLayer = () => {};
  const resizeCanvas = (w) => {
    var scale = w / scene.width;
    stage.width(scene.width * scale);
    stage.height(scene.height * scale);
    stage.scale({
      x: scale,
      y: scale,
    });
    // stage.x(GRID * scale);
    // stage.y(GRID * scale);
  };
  const createMe = () => {
    const pos = convertMe(me);
    circle = new Konva.Circle({
      ...pos,
      radius: radius,
      fill: '#f01d8c',
    });

    // add the shape to the layer
    layer.add(circle);
  };
  const createOuter = () => {
    var rect1 = new Konva.Rect({
      x: 0,
      y: 0,
      width: data.scene.width,
      height: data.scene.height,

      stroke: '#cccccc',
      strokeWidth: 12,
    });
    // add the shape to the layer
    layer.add(rect1);
  };

  const init = () => {
    scene.width = data.scene.width;
    scene.height = data.scene.height;
    stage = new Konva.Stage({
      container: 'mapCanvas',
      ...scene,
      x: 0,
      Y: 0,
      draggable: false,
    });
    stage.container().style.backgroundColor = '#E8E6E3';

    layer = new Konva.Layer();
    stage.add(layer);
    // createOuter();
    createMe();
    loadData();
    updateSelected(activeItem);
  };
  const loadData = () => {
    data.walls.forEach((x) => {
      createWall({
        config: x,
      });
    });
    data.items.forEach((x, i) => {
      x.meta.idx = i + 2;
      createItem({
        config: x,
      });
    });
  };

  return {
    stage,
    loadData,
    createItem,
    resizeCanvas,
    initMap: init,
  };
};
